import React, { Suspense } from 'react';

export const PlyrLoadable = (props) => {
  if (typeof global.window === 'undefined') {
    return null;
  }

  const Plyr = React.lazy(() => import('./plyr-lazy'));

  return (
    <Suspense fallback={null}>
      <Plyr {...props} />
    </Suspense>
  );
};

export default PlyrLoadable;
