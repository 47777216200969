import styled from 'styled-components';

export const IframeContainer = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  max-width: 900px;
  padding-top: 56.25%;
`;

export const Iframe = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
`;
