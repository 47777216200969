import styled from 'styled-components';
import Link from './link';

const Cta = styled(Link)`
  display: inline-block;
  position: relative;
  margin-bottom: ${({ nomargin }) => (nomargin ? '0' : '18px')};
  margin-right: 30px;
  padding-left: 35px;
  color: ${({ theme, color }) => theme.colors[color] || theme.colors.secondary};
  font-size: 18px;
  font-weight: 500;
  line-height: 1.2;
  letter-spacing: 0.07em;
  text-decoration: none;
  z-index: 1;
  cursor: ${({ to }) => (to ? 'pointer' : 'default')};

  &:before {
    content: '';
    position: absolute;
    top: calc(50% - 1px);
    left: 0;
    width: 20px;
    height: 2px;
    background: ${({ theme, color }) => theme.colors[color] || theme.colors.secondary};
    transition: left .2s;
  }

  &:hover {
    &:before {
      left: ${({ to }) => (to ? '7.5px' : '0px')};
    }
  }
`;

export default Cta;
